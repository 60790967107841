<template>
    <div class="mainWrapper pb-0">
        <section class="blockElement space pb-4 beforeNone">
             <div class="container">
                 <div class="row justify-content-center">
                     <div class="col-12 text-center">
                         <h1 class="bold mb-md-3" v-html="$t('pricing.content1')"></h1>
                         <h5 class="semibold" v-html="$t('pricing.content2')"></h5>
                         <p>{{$t('pricing.content3')}}  
                            <span class="secondary bold" >{{$t('pricing.content139')}}</span> {{$t('pricing.content140')}} 
                            <span class="secondary bold">{{$t('pricing.content141')}}</span> {{$t('pricing.content142')}} 
                            <router-link :to="{path : '/select-broker',query : {type : 'integrated'}}" class="secondary " >{{$t('pricing.content143')}}</router-link></p>
                     </div>
                 </div>
             </div>
        </section>
        <section class="blockElement">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-12 pb-4 pb-md-5">
                        <div class="bg-white subscriptionPlan-table">
                            <div class="tableTitle menutextBG">
                                <h3 class="mb-0">{{$t('pricing.content4')}}</h3>
                            </div>
                            <div class="responsive-table">
                                <table class="table table-bordered text-center m-0">
                                    <tbody>
                                        <tr>
                                            <th class="text-start border-0">&nbsp;</th>
                                            <td>{{$t('pricing.content5')}}</td>
                                            <td>{{$t('pricing.content6')}}</td>
                                            <td>{{$t('pricing.content7')}} </td>
                                            <td>{{$t('pricing.content8')}}</td>
                                        </tr>
                                        <tr>
                                            <th class="text-start">{{$t('pricing.content9')}}</th>
                                            <td>{{$t('pricing.content10')}}</td>
                                            <td>{{$t('pricing.content11')}}</td>
                                            <td>{{$t('pricing.content12')}}</td>
                                            <td>{{$t('pricing.content13')}}</td>
                                        </tr>
                                        <tr>
                                            <th class="text-start">{{$t('pricing.content14')}}</th>
                                            <td>-</td>
                                            <td class="secondary">{{$t('pricing.content15')}}</td>
                                            <td class="secondary">{{$t('pricing.content16')}}</td>
                                            <td class="secondary">{{$t('pricing.content17')}}</td>
                                        </tr>
                                        <tr>
                                            <th class="text-start">{{$t('pricing.content18')}}</th>
                                            <td colspan="4">{{$t('pricing.content19')}}</td>
                                        </tr>
                                        <tr>
                                            <th class="text-start">{{$t('pricing.content20')}}</th>
                                            <td class="secondary f-18" colspan="4">{{$t('pricing.content21')}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-10 text-center">
                        <p>{{$t('pricing.content22')}}<router-link :to="{path : '/select-broker',query : {type : 'co-branded'}}" class="secondary">{{$t('pricing.content143')}}</router-link>.</p>
                        <p>{{$t('pricing.content23')}} </p>
                    </div>
                </div>
            </div>
        </section>
        <section class="blockElement space "> 
            <div class="container">
                <div class="row justify-content-center text-center">
                    <div class="col-12">
                        <h2 class="mb-2" v-html="$t('pricing.content24')"></h2>
                        <h5 class="medium mb-4 mb-md-5">{{$t('pricing.content25')}}</h5>
                    </div>
                    <div class="col-12 col-md-4">
                        <div class="card-body numaricPlan">
                            <div class="gradientSec"><span class="counterd">{{$t('pricing.content26')}}</span></div>
                            <p>{{$t('pricing.content27')}}</p>
                        </div>
                    </div>
                    <div class="col-12 col-md-4">
                        <div class="card-body numaricPlan">
                             <div class="gradientSec"><span class="counterd">{{$t('pricing.content28')}}</span></div>
                            <p>{{$t('pricing.content29')}} </p>
                        </div>
                    </div>
                    <div class="col-12 col-md-4">
                        <div class="card-body numaricPlan">
                             <div class="gradientSec"><span class="counterd">{{$t('pricing.content30')}}</span></div>
                            <p>{{$t('pricing.content31')}} </p>
                        </div>
                    </div>
                </div>
            </div>
        </section> 
        <section class="blockElement space pt-0 calculations whiteBox"> 
            <Form class="login-content" @submit="submit">
                <div class="container">
                    <div class="row bg-white m-0">
                        <div class="col-12 col-md-4">
                            <div class="brokerSelect form-group position-relative filter-dropdown">
                                <label>{{$t('pricing.content32')}} </label>
                                <a @click="selectButton = !selectButton" href="javascript:void(0)" class="selectButton form-control d-flex align-items-center justify-content-between">
                                    <span class="d-flex align-items-center">
                                        <v-lazy-image class="me-2" :src="`/assets/images/clientLogo/${selectedBroker.logoUrl.split('/')[3].split('.')[0]}.svg`" alt="Broker logo" loading="lazy" width="60"  v-if="Object.keys(selectedBroker).length" />{{(Object.keys(selectedBroker).length) ? selectedBroker.name : 'Select Broker'}}</span>
                                    <vue-feather size="20" type="chevron-down"></vue-feather>
                                </a>
                                <ul class="dropdown_menu_animated scrollable" :class="selectButton ? 'show' : ''" > 
                                    <li class="form-group mb-1">
                                        <input type="text" :placeholder="$t('pricing.content33')" class="form-control radius-0" v-model="search" />
                                    </li>
                                    <li v-if="getBroketsList.length == 0">
                                        <a href="javascript:void(0)" class="d-flex align-items-center">
                                            {{$t('changes.text25')}}
                                        </a>
                                    </li>
                                    <li v-for="list in getBroketsList" :key="list.identifier">
                                        <a href="javascript:void(0)" class="d-flex align-items-center" @click="selectedBroker = list;selectButton = false;search = '';showResult = false">
                                            <v-lazy-image height="30" class="me-2" :src="`/assets/images/clientLogo/${list.logoUrl.split('/')[3].split('.')[0]}.svg`" alt="Broker logo" loading="lazy" width="60" />
                                            <span>{{list.name}}</span>
                                        </a>
                                    </li>
                                </ul>
                                <span class="text-danger" v-if="!Object.keys(selectedBroker).length && noBrokerSelected">{{$t('changes.text26')}}</span>
                            </div>
                        </div>
                        <div class="col-12 col-md-4">
                            <div class="form-group">
                                 <label>{{$t('pricing.content35')}}</label>
                                 <Field type="number" class="form-control" :placeholder="$t('pricing.content35')" :rules="{required: true,min_value:1}" name="Leader Strategies" v-model="strategies" @input="showResult = false" />
                                <ErrorMessage class="text-danger" name="Leader Strategies" />
                            </div>
                        </div>
                        <div class="col-12 col-md-4">
                            <div class="form-group">
                                 <label>{{$t('pricing.content52')}}</label>
                                 <Field as="select" class="form-control form-select" placeholder="" name="Months" v-model="month" rules="required" @change="showResult = false">
                                    <option value="">{{$t('changes.text27')}}</option>
                                    <option  v-for="value,key in static_vars.SubscriptionMonthJson" :value="key" :key="key">{{value}}</option>
                                </Field>
                                 <ErrorMessage name="Months" class="text-danger" />
                            </div>
                        </div>
                        <div class="col-12 text-center my-4">
                            <button type="submit" class="button fillBtn zulu_btn py-4 px-md-5">{{$t('pricing.content36')}}</button>
                        </div>
                        <div class="col-12" v-if="subscriptionAmount && month && strategies && showResult">
                            <div class="calculationResult flex-between">
                                <div class="d-flex align-items-center ps-3" v-if="Object.keys(selectedBroker).length">
                                    <v-lazy-image class="me-2" :src="`/assets/images/clientLogo/${selectedBroker.logoUrl.split('/')[3].split('.')[0]}.svg`" alt="Broker logo" loading="lazy" width="70" />
                                    <span>{{selectedBroker.name}}</span>
                                </div>
                                <div class="d-flex align-items-center" v-if="selectedBroker?.freeCopyTrading">
                                    <div class="brokerPlan menutextBG d-flex align-items-center">
                                        <span class="me-3 text-white">{{$t('pricing.content138')}} </span>
                                    </div>
                                </div>
                                <div class="d-flex align-items-center" v-else>
                                    <div class="brokerPlan secondary" v-if="month">{{static_vars.SubscriptionMonthJson[month] || ''}}</div>
                                    <div class="brokerPlan">${{perLeaderCharges[month]}}{{$t('pricing.content38')}}</div>
                                    <div class="brokerPlan menutextBG d-flex align-items-center">
                                        <span class="me-3 text-white">{{$t('pricing.content39')}} </span>
                                        <span class="f-26 semibold secondary" v-if="month">${{subscriptionAmount || 0}}({{static_vars.SubscriptionDiscountJson[month] || ''}})</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Form>
        </section>
        <section class="blockElement pt-0 space"> 
            <div class="container">
                <div class="row text-center">
                    <div class="col-12">
                        <a :href="store.user?.access_token ? static_vars.domainURL+'register/add-trading-account' : 'javascript:void(0)'" class="underline bold mb-3 d-inline-flex secondary" @click="deepLink()">{{$t('pricing.content41')}}</a>
                        <p v-html="$t('pricing.content42')"></p>
                        <p v-html="$t('pricing.content43')"></p>
                    </div>
                </div>
            </div>
        </section> 
        <section class="blockElement py-0 space whiteBox newSubscription"> 
            <div class="container">
                <div class="row bg-white m-0 align-items-center">
                    <div class="col-12 text-center">
                        <h2 class="beforeBorder position-relative" v-html="$t('pricing.content44')"></h2>
                    </div>
                    <div class="col-12 col-md-7">
                        <ul class="newOrderList">
                            <li v-html="$t('pricing.content45')"></li>
                            <!-- <li v-html="$t('pricing.content46')"></li> -->
                            <li v-html="$t('pricing.content47')"></li>
                            <li v-html="$t('pricing.content48')"></li>
                            <li v-html="$t('pricing.content49')"></li>
                            <li v-html="$t('pricing.content50')"></li>
                            <li v-html="$t('pricing.content51')"></li>
                        </ul>
                    </div>
                    <div class="col-12 col-md-5">
                        <v-lazy-image class="me-2" src="/assets/images/newPlan.svg" alt="Broker logo" loading="lazy" width="482" height="259"/>
                    </div>
                </div>
            </div>
        </section> 
        <section class="blockElement space faqs automated6">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <h2 class="mb-md-4 pb-4">{{$t('pricing.content53')}}</h2>
                    </div>
                    <div class="col-12">
                        <div class="accordion accordion-flush" id="accordionFlushExample">
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="flush-headingOne">
                                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                    {{$t('pricing.content55')}}
                                  </button>
                                </h2>
                                <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                  <div class="accordion-body"><p>{{$t('pricing.content54')}}</p></div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="flush-headingFour">
                                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
                                    {{$t('pricing.content56')}}
                                  </button>
                                </h2>
                                <div id="flush-collapseFour" class="accordion-collapse collapse" aria-labelledby="flush-headingFour" data-bs-parent="#accordionFlushExample">
                                  <div class="accordion-body">
                                   <p>{{$t('pricing.content57')}}</p>
                                   <ul class="dotList">
                                       <li>{{$t('pricing.content58')}}</li>
                                       <li>{{$t('pricing.content59')}}</li>
                                       <li>{{$t('pricing.content60')}}</li> 
                                       <li>{{$t('pricing.content61')}}</li>
                                       <li>{{$t('pricing.content62')}}</li>
                                   </ul>
                                  </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="flush-headingfive">
                                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsefive" aria-expanded="false" aria-controls="flush-collapsefive">
                                    {{$t('pricing.content63')}}
                                  </button>
                                </h2>
                                <div id="flush-collapsefive" class="accordion-collapse collapse" aria-labelledby="flush-headingfive" data-bs-parent="#accordionFlushExample">
                                  <div class="accordion-body">
                                   <p>{{$t('pricing.content64')}}</p>
                                   <ul class="dotList">
                                       <li>{{$t('pricing.content65')}} </li>
                                       <li>{{$t('pricing.content66')}}</li>
                                   </ul>
                                   <p>{{$t('pricing.content67')}}</p>
                                       <p class="mb-1">{{$t('pricing.content68')}}</p>   
                                       <p class="mb-1">{{$t('pricing.content69')}}</p> 
                                       <p class="mb-1">{{$t('pricing.content70')}}</p>
                                       <p class="mb-1">{{$t('pricing.content71')}} </p>
                                  </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="flush-headingsix">
                                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsesix" aria-expanded="false" aria-controls="flush-collapsesix">
                                    {{$t('pricing.content72')}}
                                  </button>
                                </h2>
                                <div id="flush-collapsesix" class="accordion-collapse collapse" aria-labelledby="flush-headingsix" data-bs-parent="#accordionFlushExample">
                                   <div class="accordion-body">
                                       <p>{{$t('pricing.content73')}}</p>
                                       <p>{{$t('pricing.content74')}}</p>
                                       <ul class="dotList">
                                           <li>{{$t('pricing.content75')}}</li>
                                           <li>{{$t('pricing.content76')}}</li>
                                           <li>{{$t('pricing.content77')}}</li>
                                           <li>{{$t('pricing.content78')}}
                                               <ul class="dotList mt-3">
                                                   <li>{{$t('pricing.content79')}}</li>
                                                   <li>{{$t('pricing.content80')}}</li>
                                                   <li>{{$t('pricing.content81')}}</li>
                                                   <li>{{$t('pricing.content82')}}</li>
                                               </ul>
                                           </li>
                                       </ul>
                                       <p>{{$t('pricing.content83')}}</p>
                                       <p>{{$t('pricing.content84')}} </p>
                                       <ul class="dotList">
                                           <li>{{$t('pricing.content85')}}</li>
                                           <li>{{$t('pricing.content86')}}</li>
                                           <li>->{{$t('pricing.content87')}} </li>
                                       </ul>
                                   </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="flush-headingSeven">
                                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSeven" aria-expanded="false" aria-controls="flush-collapseSeven">
                                    {{$t('pricing.content88')}}   
                                  </button>
                                </h2>
                                <div id="flush-collapseSeven" class="accordion-collapse collapse" aria-labelledby="flush-headingSeven" data-bs-parent="#accordionFlushExample">
                                  <div class="accordion-body">
                                       <ul class="dotList">
                                           <li>{{$t('pricing.content89')}}</li>
                                           <li>{{$t('pricing.content90')}}</li>
                                           <li>{{$t('pricing.content91')}}</li>
                                           <li>{{$t('pricing.content92')}}</li>
                                           <li>{{$t('pricing.content93')}}
                                               <ul class="dotList mt-3">
                                                   <li>{{$t('pricing.content94')}}</li>
                                                   <li>{{$t('pricing.content95')}}</li>
                                                   <li>{{$t('pricing.content96')}}</li>
                                                   <li>{{$t('pricing.content97')}}</li>
                                               </ul>
                                           </li>
                                       </ul>
                                  </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="flush-headingEight">
                                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseEight" aria-expanded="false" aria-controls="flush-collapseEight">
                                    {{$t('pricing.content98')}}
                                  </button>
                                </h2>
                                <div id="flush-collapseEight" class="accordion-collapse collapse" aria-labelledby="flush-headingEight" data-bs-parent="#accordionFlushExample">
                                  <div class="accordion-body">
                                   <ul class="dotList">
                                       <li>{{$t('pricing.content99')}}</li>
                                       <li>{{$t('pricing.content100')}}
                                           <ul class="dotList mt-3">
                                               <li>{{$t('pricing.content101')}}</li>
                                               <li>{{$t('pricing.content102')}}</li>
                                               <li>{{$t('pricing.content103')}}
                                                   <ul class="dotList mt-3">
                                                       <li>{{$t('pricing.content104')}}</li>
                                                       <li>{{$t('pricing.content105')}}</li>
                                                   </ul>
                                               </li>
                                           </ul>
                                       </li>
                                   </ul>
                                  </div>
                                </div>
                            </div>

                            <div class="accordion-item">
                               <h2 class="accordion-header" id="flush-headingNine">
                                 <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseNine" aria-expanded="false" aria-controls="flush-collapseNine">
                                   {{$t('pricing.content106')}}
                                 </button>
                               </h2>
                               <div id="flush-collapseNine" class="accordion-collapse collapse" aria-labelledby="flush-headingNine" data-bs-parent="#accordionFlushExample">
                                 <div class="accordion-body">
                                   <p>{{$t('pricing.content107')}}</p>
                                  <ul class="dotList">
                                      <li>{{$t('pricing.content108')}}</li>
                                      <li>{{$t('pricing.content109')}} <router-link class="secondary" :to="{path : '/select-broker',query :{type : 'co-branded'}}">here.</router-link></li>
                                      <li>{{$t('pricing.content110')}}</li>
                                      <li>{{$t('pricing.content111')}}</li>
                                      <li>{{$t('pricing.content112')}}</li>
                                  </ul>
                                 </div>
                               </div>
                           </div>
                           <div class="accordion-item">
                               <h2 class="accordion-header" id="flush-headingTen">
                                 <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTen" aria-expanded="false" aria-controls="flush-collapseTen">
                                   {{$t('pricing.content113')}}
                                 </button>
                               </h2>
                               <div id="flush-collapseTen" class="accordion-collapse collapse" aria-labelledby="flush-headingTen" data-bs-parent="#accordionFlushExample">
                                 <div class="accordion-body">
                                   <p>{{$t('pricing.content114')}}</p>
                                  <ul class="dotList">
                                      <li>{{$t('pricing.content115')}}</li>
                                      <li>{{$t('pricing.content116')}}</li>
                                      <li>{{$t('pricing.content117')}}</li>
                                      <li>{{$t('pricing.content118')}}</li>
                                      <li>{{$t('pricing.content119')}}</li>
                                      <li>{{$t('pricing.content120')}}</li>
                                  </ul>
                                 </div>
                               </div>
                           </div>
                           <div class="accordion-item">
                               <h2 class="accordion-header" id="flush-headingEleven">
                                 <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseEleven" aria-expanded="false" aria-controls="flush-collapseEleven">
                                   {{$t('pricing.content121')}}
                                 </button>
                               </h2>
                               <div id="flush-collapseEleven" class="accordion-collapse collapse" aria-labelledby="flush-headingEleven" data-bs-parent="#accordionFlushExample">
                                 <div class="accordion-body">
                                  
                                  <ul class="dotList">
                                      <li>{{$t('pricing.content122')}}</li>
                                      <li>{{$t('pricing.content123')}}</li>
                                      <li>{{$t('pricing.content124')}} </li>
                                  </ul>
                                 </div>
                               </div>
                           </div>
                           <div class="accordion-item">
                               <h2 class="accordion-header" id="flush-headingTwelve">
                                 <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwelve" aria-expanded="false" aria-controls="flush-collapseTwelve">
                                   {{$t('pricing.content125')}} 
                                 </button>
                               </h2>
                               <div id="flush-collapseTwelve" class="accordion-collapse collapse" aria-labelledby="flush-headingTwelve" data-bs-parent="#accordionFlushExample">
                                 <div class="accordion-body">
                                  
                                  <ul class="dotList">
                                      <li>{{$t('pricing.content126')}}</li>
                                      <li>{{$t('pricing.content127')}}</li>
                                      <li>{{$t('pricing.content128')}}</li>
                                      <li>{{$t('pricing.content129')}}</li>
                                      <li>{{$t('pricing.content130')}}</li>
                                  </ul>
                                 </div>
                               </div>
                           </div>
                           <div class="accordion-item">
                               <h2 class="accordion-header" id="flush-headingThirteen">
                                 <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThirteen" aria-expanded="false" aria-controls="flush-collapseThirteen">
                                   {{$t('pricing.content131')}}
                                 </button>
                               </h2>
                               <div id="flush-collapseThirteen" class="accordion-collapse collapse" aria-labelledby="flush-headingThirteen" data-bs-parent="#accordionFlushExample">
                                 <div class="accordion-body">
                                  
                                  <ul class="dotList">
                                      <li>{{$t('pricing.content132')}}</li>
                                      <li>{{$t('pricing.content133')}} </li>
                                      <li>{{$t('pricing.content134')}}</li>
                                      <li>{{$t('pricing.content135')}}</li>
                                      <li>{{$t('pricing.content136')}}</li>
                                      <li>{{$t('pricing.content137')}}</li>
                                  </ul>
                                 </div>
                               </div>
                           </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
     </div>
     <loginPopup v-if="showLoginPopup"></loginPopup>
</template>
<script>
    import { myStore } from "@/store/pinia-store";
     import { Form, Field, ErrorMessage } from "vee-validate";
    import $ from 'jquery'
    export default {
        setup() {
            const store = myStore();
            return { store };
        },
        components: {
            Form,
            Field,
            ErrorMessage,
          },
        data() {
            return {
                selectButton: false,
                search : '',
                selectedBroker : {},
                month : '',
                strategies : '',
                noBrokerSelected : false,
                subscriptionAmount : 0,
                freeSubscription : false,
                perLeaderCharges : {
                    1 : 10,
                    3 : 9.5,
                    6 : 9,
                    12 : 8.3
                },
                showResult : false,
                showLoginPopup: false,
            }
        },
        computed : {
            getBroketsList(){
                if(this.store.allBrokersList.length){
                    return this.store.allBrokersList.filter(v=>(this.search.trim())? v.name.toLowerCase().includes(this.search.toLowerCase()):true)
                }else{
                    return []
                }
            }
        },
        methods:{
            getBrokersList(){
                this.store.getAllBrokersList({},true)
            },
            submit(){
                if(Object.keys(this.selectedBroker).length){
                    this.noBrokerSelected = false
                    if(this.static_vars.freeSubscriptionID.includes(this.selectedBroker?.id)){
                        this.freeSubscription = true
                    }else{
                        this.freeSubscription = true
                        if(parseInt(this.month) == 1){
                            this.subscriptionAmount = parseFloat(parseInt(this.strategies)*10*parseInt(this.month)).toFixed(2)
                        }else if(parseInt(this.month) == 3){
                            this.subscriptionAmount = parseFloat((parseInt(this.strategies)*9.5*parseInt(this.month))).toFixed(2)
                        }else if(parseInt(this.month) == 6){
                            this.subscriptionAmount = parseFloat((parseInt(this.strategies)*9*parseInt(this.month))).toFixed(2)
                        }else if(parseInt(this.month) == 12){
                            this.subscriptionAmount = parseFloat((parseInt(this.strategies)*8.3*parseInt(this.month))).toFixed(2)
                        }
                    }
                    this.showResult = true
                }else{
                    this.noBrokerSelected = true
                    this.showResult = false
                }
            },
            deepLink(){
                if(this.store.user?.access_token){
                    return false
                } else {
                    this.showLoginPopup = true
                }
            }
        },
        mounted(){
            const _that = this;
            $(document).on("click", function (event) {
                if (!$(event.target).closest(".filter-dropdown").length) {
                    _that.selectButton = false;
                }
            });
        },
        created(){
            this.getBrokersList()
        },
    }
</script>